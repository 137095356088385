import React, { useEffect, useState } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import logo from "../../../../src/assets/images/logo.png";
import combinedServices from "../../../shared/services/user-service";
import { useParams } from "react-router-dom";
import "./NewCdHeatSheetFormat.scss";
import moment from "moment";

export default function NewDriverHeatSheetFormat() {
  const [driverHeatSheet, setDriverHeatSheet] = useState([]);
  const [date, setDate] = useState("");
  const [classes, setClasses] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const eventId = useParams().id;
  const pdfExportComponent = React.useRef(null);

  useEffect(async () => {
    try {
      // Fetch participant-wise heats
      const response = await combinedServices.getParticipantWiseHeats({
        eventId: eventId,
      });
  
      // Sort by first name and last name
      const sortedData = response.data.result.sort((a, b) => {
        const firstNameComparison = a.user_user_firstname.localeCompare(
          b.user_user_firstname
        );
        if (firstNameComparison !== 0) {
          return firstNameComparison; // If first name differs, sort by first name
        }
        return a.user_user_lastname.localeCompare(b.user_user_lastname); // Then sort by last name
      });
  
      // Group data by participant
      const groupedData = [];
      for (let item of sortedData) {
        const findItemIndex = groupedData.findIndex(
          (p) =>
            p.participant?.data[0].event_participant_id ==
            item.event_participant_id
        );
        if (findItemIndex == -1) {
          const name = `${item.user_user_firstname} ${item.user_user_lastname}`;
          groupedData.push({ participant: { name: name, data: [item] } });
        } else {
          groupedData[findItemIndex]["participant"].data.push(item);
        }
      }
  
      // Fetch event details
      const eventDetailsResponse = await combinedServices.getEventDetail(eventId);
      const time_zone = eventDetailsResponse.data.event_time_zone;
      const final_Date =
        timeaccotolocation2(
          eventDetailsResponse.data.event_event_start,
          time_zone
        ).split(" ")[1] +
        " " +
        timeaccotolocation2(
          eventDetailsResponse.data.event_event_start,
          time_zone
        ).split(" ")[2] +
        "-" +
        timeaccotolocation2(
          eventDetailsResponse.data.event_event_end,
          time_zone
        ).split(" ")[2];
      setDate(final_Date);
      setEventTitle(eventDetailsResponse.data.event_full_title);
  
      // Fetch and sort classes
      const eventClassesData = await combinedServices.countByClasses(eventId);
      const madeClasses = eventClassesData.data.participants
        .filter((classItem) => classItem.class_status == "MADE")
        .sort((a, b) => new Date(a.run_date) - new Date(b.run_date));
      setClasses(madeClasses);
  
      // Map classes data into participant data
      for (let item of groupedData) {
        for (let participant of item.participant.data) {
          const findClass = madeClasses.find(
            (cls) => cls.class_id == participant.class_id
          );
          if (findClass) {
            participant.run_date = findClass.run_date;
            participant.order_key = findClass.order_key;
          }
        }
  
        // Sort each participant's data by run_date, order_key, and heatSheet_round
        item.participant.data.sort((a, b) => {
          const runDateComparison = new Date(a.run_date) - new Date(b.run_date);
          if (runDateComparison !== 0) return runDateComparison;
  
          const orderKeyComparison = a.order_key - b.order_key;
          if (orderKeyComparison !== 0) return orderKeyComparison;
  
          return a.heatSheet_round - b.heatSheet_round; // Finally, sort by round
        });
      }
  
      setDriverHeatSheet(groupedData);
    } catch (error) {
      console.log("err ", error);
    }
  }, []);
  

  const PDFExportPageTemplate = (props) => (
    <div>
      {/* Logo */}
      <img
        src={logo}
        alt="Logo"
        style={{
          position: "absolute",
          top: "20px",
          right: "30px",
          height: "100px",
          width: "100px",
        }}
      />

      {/* Page Number */}
      <span
        style={{
          position: "absolute",
          bottom: "15px",
          right: "30px",
          fontSize: "12px",
          color: "black",
          textAlign: "center",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </span>
    </div>
  );

  const timeaccotolocation2 = (date, timeZone) => {
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(date);
    const localMoment = utcMoment.tz(timeZone);

    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
  };

  return (
    <div className="new-driver-heatsheet">
      <button onClick={(e) => pdfExportComponent.current.save()}>
        download
      </button>
      <PDFExport
        forcePageBreak=".page-break"
        ref={pdfExportComponent}
        pageTemplate={PDFExportPageTemplate}
        paperSize="Letter"
        landscape="true"
        margin={{
          top: "0.4in",
          left: "0.4in",
          right: "0.4in",
          bottom: "0.4in",
        }}
        fileName="heatsheet"
      >
        {console.log('driverheetsheet',driverHeatSheet)}
        {driverHeatSheet.map((driver) => (
          <div class="">
            <h1>
              {eventTitle} - {date}
            </h1>
            <h2>Driver: {driver.participant.name}</h2>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>Round</th>
                  <th style={{ width: "100px" }}>Heat #</th>
                  <th style={{ width: "300px" }}>Class</th>
                </tr>
              </thead>
              <tbody>
                {driver.participant.data.map((item) =>
                  // classes.map((cls) => {
                  //   if (item.class_id == cls.class_id) {
                  //     return (
                        <tr>
                          <td>{item.heatSheet_round}</td>
                          <td>{item.heatSheet_event_heat_number}</td>
                          <td>{item.Class_Name}</td>
                        </tr>
                  //     );
                  //   }
                  // })
                )}
                <tr className="page-break"></tr>
              </tbody>
            </table>
          </div>
        ))}
      </PDFExport>
    </div>
  );
}

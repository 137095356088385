import { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Typography,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import getLocalData from "../../shared/localStorage/getData";
import combinedServices from "../../shared/services/user-service";
import getRemoveData from "../../shared/localStorage/removeData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

export default function EditRaceSeriesModal({
  open,
  handleClose,
  series,
  userId = 825,
  reload,
  setReload
}) {
  const [raceSeriesTitle, setRaceSeriesTitle] = useState("");
  const [raceSeriesDescription, setRaceSeriesDescription] = useState("");
  const [selectedRaces, setSelectedRaces] = useState([]);

  useEffect(async () => {
    if (series) {
      setRaceSeriesTitle(series.title);
      setRaceSeriesDescription(series.description);
    }
  }, [series, open]);

  useEffect(async () => {
    async function fetchData() {
      const res = await combinedServices.getRacesForSeries();
      if (res.data && series) {
        const seriesEvents = series.events.map(event => event.id);
        const raceData = res.data.map((item) => ({
          ...item,
          selected: seriesEvents.includes(item.id),
        }));
        setSelectedRaces(raceData);
      }
    }
    await fetchData();
  }, [series]);

  const handleCheckboxChange = (event, index) => {
    const updatedRaces = [...selectedRaces];
    updatedRaces[index].selected = event.target.checked;
    setSelectedRaces(updatedRaces);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedRaceIds = selectedRaces
      .filter((race) => race.selected)
      .map((race) => race.id);
    let data = {
      id:series.id,  
      title: raceSeriesTitle,
      description: raceSeriesDescription,
      eventIds: selectedRaceIds,
      created_by: userId,
    };
    try {
      const createdResponse = await combinedServices.updateSeries(data);
      handleClose();
      swal("Event Status Update Successfully").then(()=>{
        setReload(prev=>!prev)
      })
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" component="h5" gutterBottom>
          Edit Race Series
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Race Series Name"
            value={raceSeriesTitle}
            onChange={(e) => setRaceSeriesTitle(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Race Series Description"
            value={raceSeriesDescription}
            onChange={(e) => setRaceSeriesDescription(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {selectedRaces.map((item, index) => (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={item.selected}
                      onChange={(e) => handleCheckboxChange(e, index)}
                      name={item.full_title}
                    />
                  }
                  label={item.full_title}
                />
              ))}
              {/* </Box> */}
            </FormGroup>
            <button type="submit"  className="swal-button">
              Submit
            </button>
          </FormControl>
        </form>
      </Box>
    </Modal>
  );
}

import React, { useState } from "react";
import { Races, RaceSeries } from "./types";
import Header from "../clubDashboard/Header/Header";
import EventManagement from "../../Pages/ClubDashboard/EventManagement/EventManagement";
import Series from "./Series";
import RaceSeriesModal from "./RaceSeriesModal";

export default function EventAndSeriesTab() {
  const [seriesOrTab, setSeriesOrTab] = useState(Races);
  const [openSeriesModal, setOpenSeriesModal] = useState(false);
  const [newSeries,setNewSeries] = useState(false)

  const handleTabChange = (val) => {
    setSeriesOrTab(val);
  };
  const handleCloseSeriesModal = () => {
    setOpenSeriesModal(false);
    setNewSeries((prev)=>!prev)
  };

  const btnStyle = {
    margin: "20px 0",
    display:'flex',
    justifyContent:'space-between'
  };

  return (
    <div>
      <Header />
      <div className="dbRight">
        <ul style={btnStyle}>
          <div>
            <li
              onClick={(e) => handleTabChange(Races)}
              className="blueBtn ml10"
              style={{
                backgroundColor: seriesOrTab == Races ? "#1c60a6" : "",
                color: seriesOrTab == Races ? "white" : "",
              }}
            >
              <a>Races</a>
            </li>
            <li
              onClick={(e) => handleTabChange(RaceSeries)}
              className="blueBtn ml10"
              style={{
                backgroundColor: seriesOrTab == RaceSeries ? "#1c60a6" : "",
                color: seriesOrTab == RaceSeries ? "white" : "",
              }}
            >
              <a>Series</a>
            </li>
          </div>
         { seriesOrTab==RaceSeries && <button
            className="blueBtn ml10 create-race-series"
            style={{marginRight:'20px'}}
            onClick={() => setOpenSeriesModal(true)}
          >
            Create New Series
          </button>}
        </ul>

        {seriesOrTab == RaceSeries && <Series newSeries={newSeries}/>}
        {seriesOrTab == Races && <EventManagement />}
      </div>

      <RaceSeriesModal
        open={openSeriesModal}
        handleClose={handleCloseSeriesModal}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import combinedServices from "../../shared/services/user-service";
import logo from "../../assets/images/logo.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./RaceSeries.scss";
import swal from "sweetalert";
import EditRaceSeriesModal from "./EditRaceSeriesModal";
import moment from "moment";

const allStatus = ["upcoming", "archived", "completed", "ongoing"];
const seriesStatus = ["ACTIVE", "ARCHIVED"];

export default function Series({newSeries}) {
  const [series, setSeries] = useState([]);
  const [openEditSeriesModal, setEditOpenSeriesModal] = useState(false);
  const [reload,setReload] = useState(false)
  const [editSeriesDetails, setEditSeriesDetails] = useState("");

  useEffect(async () => {
    try {
      const seriesResponse = await combinedServices.getSeries({ userId: 825 });
      setSeries(seriesResponse.data);
    } catch (err) {
      console.log("err", err);
    }
  }, [reload,newSeries]);

  const handleSeriesStatusChange = async (value, i) => {
    try {
      console.log("value is ", value);
      const tempSeries = [...series];
      tempSeries[i].series_status = value;
      setSeries(tempSeries);
      let data = {
        id: tempSeries[i].id,
        series_status: value,
      };
      const updatedResponse = await combinedServices.updateSeries(data);
      setReload(prev=>!prev)
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleEventStatusChange = async (value, id, seriesIndex, raceIndex) => {
    try {
      console.log("value", id, seriesIndex, raceIndex);
      const tempSeries = [...series];
      tempSeries[seriesIndex].events[raceIndex].event_status = value;
      setSeries(tempSeries);
      let updatedResponse = await combinedServices.updateEventStatus(
        { event_status: value },
        id
      );
      console.log("updatedResponse", updatedResponse);
      setReload(prev=>!prev)
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleDeleteSeries = async (id) => {
    try {
      swal({
        title: "Once deleted, you will not be able to restore this event!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const deletedResponse = await combinedServices.deleteSeries({
            id: id,
          });
          console.log("deletedResponse ", deletedResponse);
          setReload(prev=>!prev)
        }
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleCloseSeriesModal = () => {
    setEditOpenSeriesModal(false);
  };
  const handleEditSeriesModal = (series, index) => {
    setEditSeriesDetails(series);
    setEditOpenSeriesModal(true);
  };

  const dateConvert=(value)=>{
    const currentDate = (desiredTimeZone) => {
      desiredTimeZone =
        desiredTimeZone == ""
          ? "Canada/Eastern"
          : desiredTimeZone;
      return new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric",
        timeZone: desiredTimeZone,
        hour12: true,
      }).format(new Date());
    };
    const registrationOpen =
      moment(currentDate(value.time_zone)).format(
        "YYMMDD HH:mm:ss"
      ) <
      moment(
        timeaccotolocation2(
          value.registration_open,
          value.time_zone
        )
      ).format("YYMMDD HH:mm:ss");

    const registrationOngoing =
      moment(currentDate(value.time_zone)).format(
        "YYMMDD HH:mm:ss"
      ) >=
        moment(
          timeaccotolocation2(
            value.registration_open,
            value.time_zone
          )
        ).format("YYMMDD HH:mm:ss") &&
      moment(currentDate(value.time_zone)).format(
        "YYMMDD HH:mm:ss"
      ) <=
        moment(
          timeaccotolocation2(
            value.registration_close,
            value.time_zone
          )
        ).format("YYMMDD HH:mm:ss");
    const registrationClosed =
      moment(currentDate(value.time_zone)).format(
        "YYMMDD HH:mm:ss"
      ) >
      moment(
        timeaccotolocation2(
          value.registration_close,
          value.time_zone
        )
      ).format("YYMMDD HH:mm:ss");

      if(registrationClosed){
        const date = moment(
          timeaccotolocation2(
            value.registration_close,
            value.time_zone
          )
        ).format("ddd, MMM DD YYYY")
        
        return <span>Registration Closed {date}</span>
      }
      if(registrationOngoing){
        const date = moment(
          timeaccotolocation2(
            value.registration_close,
            value.time_zone
          )
        ).format("ddd, MMM DD YYYY")

        return <span className='blue'>Registration Ongoing {date}</span>
      }

      if(registrationOpen){
        const date = moment(
          timeaccotolocation2(
            value.registration_open,
            value.time_zone
          )
        ).format("ddd, MMM DD YYYY")
        return <span className='black'> Registration Open {date}</span>
      }
  }
  const timeaccotolocation2 = (x, timeZone) => {
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);

    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
  };

  return (
    <div className="race-series">
      <ul className="uocomingRaces ">
        <Accordion allowZeroExpanded>
          {series?.map((series, seriesIndex) => (
            <AccordionItem key={series.id}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <li className="showRaces">
                    <div className="left rght">
                      <div className="icon">
                        <img src={series.image ? series.image : logo} alt="" />
                      </div>
                      <div className="det">
                        <span className="hd" style={{ color: "black" }}>
                          {series.title}
                        </span>
                        <a>{series.description}</a>
                      </div>
                    </div>

                    <div className="">
                      <div>
                        <button
                          className="series-button"
                          onClick={() =>
                            handleEditSeriesModal(series, seriesIndex)
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="series-button"
                          onClick={() => handleDeleteSeries(series.id)}
                        >
                          Delete
                        </button>
                        <FormControl sx={{ width: 170 }}>
                          <InputLabel id="demo-simple-select-label">
                            Series Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={series.series_status}
                            label="Series Status"
                            onChange={(e) =>
                              handleSeriesStatusChange(
                                e.target.value,
                                seriesIndex
                              )
                            }
                          >
                            {seriesStatus.map((sts) => {
                              return (
                                <MenuItem key={sts} value={sts}>
                                  {sts}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </li>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {series.events.map((race, raceIndex) => (
                  <li className="race-series-races" key={race.id}>
                    <div className="left rght">
                      <div className="icon">
                        <img src={race.image ? race.image : logo} alt="" />
                      </div>
                      <div className="det">
                        <span className="hd">
                          <Link to={"/event-detail/" + race.id}>
                            {race.full_title} <br />
                          </Link>
                          {dateConvert(race)}
                        </span>
                        <a>{moment(
                                timeaccotolocation2(
                                  race.event_event_start,
                                  race.time_zone
                                )
                              ).format("ddd, MMM DD, YYYY") +
                                " - " +
                                moment(
                                  timeaccotolocation2(
                                    race.event_event_end,
                                    race.time_zone
                                  )
                                ).format("ddd, MMM DD, YYYY")} |{" "}
                          {race.boatsCount} boats {race.racersCount} drivers
                        </a>
                      </div>
                    </div>

                    <div>
                      <FormControl sx={{ width: 170 }}>
                        <InputLabel id="demo-simple-select-label">
                          Event Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={race.event_status}
                          label="Event Status"
                          onChange={(e) =>
                            handleEventStatusChange(
                              e.target.value,
                              race.id,
                              seriesIndex,
                              raceIndex
                            )
                          }
                        >
                          {allStatus.map((sts) => {
                            return (
                              <MenuItem key={sts} value={sts}>
                                {sts}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </li>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </ul>
      <EditRaceSeriesModal
        open={openEditSeriesModal}
        handleClose={handleCloseSeriesModal}
        series={editSeriesDetails}
        reload={reload}
        setReload={setReload}
      />
    </div>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import userConstants from "../../../shared/constants/user-constants";
import AltImg from "../../../assets/images/logo.png";
import "./ManageHeatsheet.scss";
import Doc from "./DocService";
// import PdfContainer from "./PdfContainer";
import { set } from "react-hook-form";
import swal from "sweetalert";
import { PDFExport, PDFPage } from "@progress/kendo-react-pdf";
import { saveAs } from "@progress/kendo-file-saver";
import jsPDF from "jspdf";
import getRemoveData from '../../../shared/localStorage/removeData';
import moment from "moment-timezone";


var showedheat = [];
let hjk = 9,
  sumik,
  gjk;
var convertedObject;
var tables = [];
export default function DownloadHeatsheet() {
  // get event id from params
  let eventId = useParams().id;
  const [data, setData] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [classes, setClasses] = useState([]);
  const [eventParticipant, setEventParticipant] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [prevHeat, setPrevHeat] = useState("");
  const [round2Data, setRound2Data] = useState(false);
  const [round3Data, setRound3Data] = useState(false);
  const [round4Data, setRound4Data] = useState(false);
  const [dates, setDates] = useState([]);
  const [eventname, setEventname] = useState("");
  const [users, setUsers] = useState();
  const [fees, setFees] = useState("");
  const [regischarge, setRegischarge] = useState("");
  const [imagestream, setImagestream] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [masterarr, setMasterarr] = useState({});
  const [participantarr, setParticipantarr] = useState({});
  const[timezone,setTimezone]=useState("")

  const pdfExportComponent = React.useRef(null);
  const pdfDoc = new jsPDF();
  let convertedArray;
  useEffect(async () => {
    let finalArr = [];
    let classArr = [];
    let dateArr = [];
    await combinedServices
      .getClassByEventId(eventId)
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        setImagestream(
          res.data.image.length > 0
            ? baseURL + "images/" + res.data.image
            : AltImg
        );
   
        setEventDetails(res.data);
        setTimezone(res.data.time_zone)
        setRegischarge(res.data.registration_charge);
        setFees(res.data.boat_charge);
        setEventname(res.data.full_title);

        let round = [];
        for (var i = 1; i <= res.data.rounds_per_class; i++) {
          round.push(i);
        }
        setRounds(round);
      })
      .catch((err) => {
        alert(err);
      });
    // setImagestream(eventDetails.image)
    await combinedServices
      .getEventParticipantForHeat({ event_id: eventId })
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
           localStorage.clear();
            window.location.href = "/login";
          });
        }
        const sortedArray = res.data.sort((a, b) => {
          const nameA = a.user.user_firstname.toUpperCase();
          const nameB = b.user.user_firstname.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setEventParticipant(sortedArray);
      })
      .catch((err) => {
        alert(err);
      });
    //getting class only
    var eventClassesData1 = await combinedServices.countByClasses(eventId);
    setTimezone(eventClassesData1.data.event.time_zone)
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null) {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name,
            rundate: classVal.run_date,
            event_class_id: classVal.event_class_id,
            order_key: classVal.order_key,
          };
          classArr.push(data);
          classArr.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
        }
        
        if (classVal.run_date != null) {
          var data1 = {
            run_date: classVal.run_date,
            class: classVal.class_name,
          };
          dateArr.push(data1);
        }
      });
      classArr?.sort((a, b) => {
        if (a.rundate === b.rundate) {
          return a.order_key - b.order_key;
        }
        return new Date(a.rundate) - new Date(b.rundate);
      });
      setClasses(classArr);
      const formattedResponse = {};

      for (const item of dateArr) {
        const runDate = item.run_date;
        const classType = item.class;
        
        if (runDate in formattedResponse) {
          formattedResponse[runDate].push(classType);
        } else {
          formattedResponse[runDate] = [classType];
        }
      }
      
      setDates(formattedResponse);
    }

    // getting all the data
    await combinedServices
      .getAllHeatSheet({ event_id: eventId })
      .then(async (res) => {
 
        res.data.map((val, i) => {
 
          return val.participants.map((itm, i) => {
            
            return finalArr.push({
              class: itm.event_participant_class.class.name,
              heat: val.heat_number,
              name:
                itm.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              nambaNumber:itm.event_participant_class.event_participant.user.nambaNumber,
              score: "",
              round: itm.round,
              id: itm.event_participant_class.event_participant.user.id,
              scratch: itm.scratch,
            });
          });
        });
        let uniqueChars = [...new Set(finalArr)];

        finalArr?.map((val) => {
          if (val.round == 2) return setRound2Data(true);
          if (val.round == 3) return setRound3Data(true);
          if (val.round == 4) return setRound4Data(true);
        });
        setData(finalArr);
      })

      .catch((err) => {
        alert(err);
      });

    setTimeout(() => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    }, 500);
  }, []);

  useEffect(() => {
    const uniqueNames = [...new Set(data.map((item) => item.id))];

    const result = uniqueNames.map((id) => {
      return {
        // name: name,
        id: id,
        AllRunDates: [
          ...new Set(
            data
              .filter((item) => item.id == id)
              .map((item) => item.rundate)
              .sort()
          ),
        ],
      };
    });
    setUsers(result);

    setUsers(result);

    // setUsers(result);
  }, [data]);
  useEffect(() => {
    convertedObject = {};

    data?.forEach((item) => {
      const className = item.class;
      const roundKey = `Round${item.round}`;
      const heatKey = `heat_round${item.round}`;
      const usernameKey = `username_round${item.round}`;
      const scratchKey = `scratch_round${item.round}`;

      if (!convertedObject.hasOwnProperty(className)) {
        convertedObject[className] = {};
      }

      const classObj = convertedObject[className];
      const roundObj = classObj.hasOwnProperty(roundKey)
        ? classObj[roundKey]
        : [];

      roundObj.push({
        [heatKey]: item.heat,
        [usernameKey]: item.name,
        scratchKey: item.scratch,
        nambaNumber:item.nambaNumber
      });

      // Sort the heats within each round in ascending order
      roundObj.sort((a, b) => {
        const heatA = (a[usernameKey]);
        const heatB = (b[usernameKey]);
        return (heatA.localeCompare(heatB));
      });

      classObj[roundKey] = roundObj;
    });

    setMasterarr(convertedObject);
  }, [data]);

  useEffect(() => {
     convertedArray = data.reduce((result, item) => {
      const { class: classname, name: username, round, heat,scratch} = item;

      if (!result[username]) {
        result[username] = {};
      }

      if (!result[username][classname]) {
        result[username][classname] = {};
      }

      result[username][classname][`Round${round}`] = heat;
      result[username][classname][`Scratch${round}`] = scratch;

      return result;
    }, {});

    setParticipantarr(convertedArray);
  }, [data]);

  // classes?.map((cls)=>{

  // })
  classes.sort((a, b) => {
    const dateComparison = new Date(a.rundate) - new Date(b.rundate);
    if (dateComparison === 0) {
      return a.order_key - b.order_key;
    }
    return dateComparison;
  });

  const getUsernameElement = (username, isScratched) => {
    if (isScratched) {
      return <div style={{ textDecoration: "line-through" }}>{username}</div>;
    }
    return username;
  };
  const tables = [];

  // for (const classInfo of classes) {
  //   const className = classInfo.name;
  //   const runDate = new Date(classInfo.rundate).toLocaleDateString("en-us", {
  //     weekday: "long",
  //     year: "numeric",
  //     month: "short",
  //     day: "numeric",
  //   });

  //   const classData = convertedObject?.[className];
  //   if (!classData) {
  //     continue;
  //   }

  //   // Find the maximum number of users for this class
  //   let maxUsers = 0;
  //   for (let i = 1; i <= 4; i++) {
  //     const roundKey = `Round${i}`;
  //     const roundData = classData[roundKey];
  //     if (roundData && roundData.length > maxUsers) {
  //       maxUsers = roundData.length;
  //     }
  //   }

  //   const tableData = [];
  //   for (let j = 0; j < maxUsers; j++) {
  //     const rowData = [];
  //     // rowData.push(<td key={`className-${j}`}>{j === 0 ? className : ""}</td>);

  //     for (let i = 1; i <= 4; i++) {
  //       const roundKey = `Round${i}`;
  //       const roundData = classData[roundKey];
  //       const heatKey = `heat_round${i}`;
  //       const usernameKey = `username_round${i}`;
  //       const scratchKey = "scratchKey";

  //       const item = roundData?.[j];
  //       const heat = item?.[heatKey];
  //       const username = item?.[usernameKey];
  //       const isScratched = item?.[scratchKey] === true;

  //       rowData.push(
  //         <td key={`heat-${i}-${j}`}>{heat}</td>,
  //         <td key={`username-${i}-${j}`}>
  //           {getUsernameElement(username, isScratched)}
  //         </td>,
  //         <td key={`score-${i}-${j}`}></td>
  //         // <td></td>
  //       );
  //     }
  //     rowData.push(
  //       <>
  //         <td></td>
  //         <td></td>
  //       </>
  //     );
  //     tableData.push(<tr key={`row-${j}`}>{rowData}</tr>);
  //   }

  //   const table = (
  //     <div key={className} className="masterTable containerz">
  //       <div
  //         style={{
  //           display: "flex",
  //           alignItems: "flex-end",
  //           justifyContent: "space-between",
  //         }}
  //       >
  //         <h3
  //           style={{
  //             display: "inline-block",
  //             fontSize: "16px",
  //           }}
  //         >
  //           {eventname}
  //         </h3>
  //         <img src={AltImg} style={{ width: "50px", height: "50px" }} />
  //       </div>
  //       <span
  //         className="hd"
  //         style={{
  //           fontWeight: "700",
  //           fontSmooth: "600",
  //           textAlign: "left",
  //           fontSize: "12px",
  //         }}
  //       >
  //         {runDate}
  //       </span>
  //       <h4
  //         style={{
  //           fontSize: "16px",
  //         }}
  //       >
  //         {className}
  //       </h4>

  //       <table
  //         cellpadding="0"
  //         cellspacing="0"
  //         style={{
  //           background: "#d2d0d0",
  //           borderSpacing: "2px",
  //         }}
  //       >
  //         <div
  //           style={{
  //             backgroundImage: `url(${imagestream})`,
  //             backgroundRepeat: "no-repeat",
  //             backgroundPosition: "center center",
  //             backgroundSize: "cover",
  //             borderRadius: "150px",
  //             position: "absolute",
  //             top: "50%",
  //             left: "50%",
  //             width: "300px",
  //             height: "300px",
  //             zIndex: "100",
  //             opacity: "0.1",
  //             margin: "-150px 0 0 -150px",
  //           }}
  //         ></div>
  //         <thead>
  //           <tr>
  //             {/* <th>CLASS</th> */}
  //             <th>Participant</th>
  //             <th>ROUND 1</th>
  //             <th>SCORE</th>
  //             {/* <th>HEAT</th> */}
  //             <th>ROUND 2</th>
  //             <th>SCORE</th>
  //             {/* <th>HEAT</th> */}
  //             <th>ROUND 3</th>
  //             <th>SCORE</th>
  //             {/* <th>HEAT</th> */}
  //             <th>ROUND 4</th>
  //             <th>SCORE</th>
  //             <th>TOTAL</th>
  //             <th>FINAL</th>
  //           </tr>
  //         </thead>
  //         <tbody>{tableData}</tbody>
  //       </table>
  //     </div>
  //   );

  //   tables.push(table);
  // }
  // Define an array of predefined colors for each heat
  const colorCodes = [
     "#808080",
    "#A9A9A9",
    "#C0C0C0",
    // "#D3D3D3",
    // '"#696969",
    // "#778899",
    // "#708090",
    // "#B0C4DE",
    "#E0E0E0",
    // ' "#F5F5F5",
]
const timeaccotolocation2 = (x) => {
  const utcMoment = moment.utc(x);
  const localMoment = utcMoment.tz(timezone);
  
  // const timeZoneAbbreviation = localMoment.format('z');
  const timeZoneOffset = localMoment.format('Z');
  return localMoment.format('dddd, MMM DD, YYYY');;
}
  for (const classInfo of classes) {
    const className = classInfo.name;
    const runDate =timeaccotolocation2(classInfo.rundate)
    // .toLocaleDateString("en-us", {
    //   weekday: "long",
    //   year: "numeric",
    //   month: "short",
    //   day: "numeric",
    // });

    const classData = convertedObject?.[className];
   
    if (!classData) {
      continue;
    }
  
    // Find the maximum number of users for this class
    let maxUsers = 0;
    for (let i = 1; i <= 4; i++) {
      const roundKey = `Round${i}`;
      const roundData = classData[roundKey];
      if (roundData && roundData.length > maxUsers) {
        maxUsers = roundData.length;
      }
    }
    let tdStyle;
    const tableData = [];

    for (let j = 0; j < maxUsers; j++) {
      const rowData = [];
  
      // Add the participant name in the first column
      const nambaNumber = classData.Round1[j]?.nambaNumber || '';
      const participantName = classData.Round1[j]?.username_round1 || '';
      rowData.push(<td key={`participant-${j}`}><div style={{display:"flex"}}> <div style={{flex:"2"}}>{nambaNumber}</div><div style={{flex:"2"}}> {participantName}</div></div></td>);
  
      for (let i = 1; i <= 4; i++) {
        const roundKey = `Round${i}`;
        const roundData = classData[roundKey];
        const heatKey = `heat_round${i}`;
        const usernameKey = `username_round${i}`;
        const scratchKey = "scratchKey";
  
        const item = roundData?.[j];
        const heat = item?.[heatKey];
        const username = item?.[usernameKey];
        const isScratched = item?.[scratchKey] === true;
        const heatContent = isScratched ? "NA" : heat

        let heatClass = '';
      // Determine the background color based on the heat value
      const heatColor = heat ? colorCodes[(parseInt(heat) - 1) % 4] : 'transparent';

      // Set the style attribute for the td element to apply the background color
      //  tdStyle = {
      //   backgroundColor: heatColor,
      // }
        // Add heat and score columns for each round
        rowData.push(
          <td key={`heat-${i}-${j}`} style={tdStyle} >{heatContent}</td>,
          // <td key={`score-${i}-${j}`}></td>
        );
  
        // Add a blank column between rounds (except for the last round)
        if (i < 4) {
          rowData.push(<td key={`blank-${i}-${j}`} style={tdStyle}></td>);
        }
      }
      rowData.push(
        <td key={`total-${j}`} style={tdStyle}>{/* Total value here */}</td>,
        <td key={`final-${j}`}>{/* Final value here */}</td>,
        <td key={`extra-column-${j}`}>{/* Content for the extra column */}</td>
      );
  
      // Calculate and add the total and final columns here if needed
      // You can add your logic to calculate totals and finals here
  
      tableData.push(<tr key={`row-${j}`}>{rowData}</tr>);
    }
  
    const table = (
      <div key={className} className="masterTable containerz">
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <h3
            style={{
              display: "inline-block",
              fontSize: "16px",
            }}
          >
            {eventname}
          </h3>
          <img src={AltImg} style={{ width: "50px", height: "50px" }} />
        </div>
        <span
          className="hd"
          style={{
            fontWeight: "700",
            fontSmooth: "600",
            textAlign: "left",
            fontSize: "12px",
          }}
        >
          {runDate}
        </span>
        <h4
          style={{
            fontSize: "16px",
          }}
        >
          {className}
        </h4>

        <table
          cellpadding="0"
          cellspacing="0"
          style={{
            background: "#d2d0d0",
            borderSpacing: "2px",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${imagestream})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              borderRadius: "150px",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "300px",
              height: "300px",
              zIndex: "100",
              opacity: "0.1",
              margin: "-150px 0 0 -150px",
            }}
          ></div>
          <thead>
            <tr>
              {/* <th>CLASS</th> */}
              <th>Driver</th>
              <th>ROUND 1</th>
              <th>SCORE</th>
              {/* <th>HEAT</th> */}
              <th>ROUND 2</th>
              <th>SCORE</th>
              {/* <th>HEAT</th> */}
              <th>ROUND 3</th>
              <th>SCORE</th>
              {/* <th>HEAT</th> */}
              <th>ROUND 4</th>
              <th>SCORE</th>
              <th>TOTAL</th>
              <th>FINAL</th>
            </tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
      </div>
    );

    tables.push(table);
  }
  
  for (let i = 0; i < data.length; i++) {
    const race = data[i];
    const rundate = classes.find((item) => item.name === race.class)?.rundate;
    // use optional chaining to avoid error when no matching item is found
    race.rundate = rundate;
  }
  // Download Pdf file of heatsheet
  const createPdf = (html) => Doc.createPdf(html);
  const renderHeatHeat = () => {
    return prevHeat;
  };
  const handleHeat = (h) => {
    if (prevHeat == h) {
      return null;
    }
    if (prevHeat != h) {
      setPrevHeat(h);
      return h;
    }
    return h;
  };

  const PDFExportPageTemplate = (props) => (
    <span
      style={{
        position: "absolute",
        bottom: "15px",
        right: "30px",
        fontSize: "12px",
        color: "black",
        textAlign: "center",
      }}
    >
      Page {props.pageNum} of {props.totalPages}
    </span>
  );

  return (
    <>
      <div className="downloadRef">
        <PDFExport
          forcePageBreak=".page-break"
          ref={pdfExportComponent}
          pageTemplate={PDFExportPageTemplate}
          paperSize="Letter"
          landscape="true"
          margin={{
            top: "0.4in",
            left: "0.4in",
            right: "0.4in",
            bottom: "0.4in",
          }}
          fileName="heatsheet"
        >
          <React.Fragment>
            <div>
              <div style={{ width: "100%" }}>{tables}</div>
          {console.log('eventparticipanttttt',eventParticipant)}
          {console.log('usersss',users)}
          {console.log('dataaaa',data)}
              <div>            
                {eventParticipant.map((ep, k) => {
                  return (
                    <>
                      <div
                        className="masterTable"
                        style={{ position: "relative" }}
                        key={k}
                      >
                        {users
                          ?.filter((item) => item.id == ep.user.id)
                          ?.map((day) => {
                            return day.AllRunDates.map((ele, i) => {
                              return (
                                <>
                                  <div className="containerz">
                                    <table>
                                      <tr>
                                        <td
                                          height="24"
                                          className="gray centerh"
                                          colSpan="5"
                                          style={{
                                            fontWeight: "700",
                                            fontSmooth: "600",
                                            textAlign: "left",
                                          }}
                                        ></td>
                                      </tr>
                                      <tr>
                                        <td colSpan="5">
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-end",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              <h4
                                                className="hd"
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                             
                                                {ep.user.nambaNumber +" " + ep.user.user_firstname +
                                                  " " +
                                                  ep.user.user_lastname}
                                                {""}
                                                <span>
                                                  (Day {i + 1} of{" "}
                                                  {day.AllRunDates.length})
                                                </span>
                                              </h4>
                                              <h3
                                                className="hd few"
                                                style={{
                                                  fontSize: "14px",
                                                  margin: "0px",
                                                }}
                                              >
                                                {eventname}
                                              </h3>
                                            </div>
                                            <img
                                              src={AltImg}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            />
                                          </div>

                                          <div
                                            style={{
                                              backgroundImage: `url(${imagestream})`,
                                              backgroundRepeat: "no-repeat",
                                              backgroundPosition:
                                                "center center",
                                              backgroundSize: "cover",
                                              borderRadius: "150px",
                                              position: "absolute",
                                              top: "30%",
                                              left: "50%",
                                              width: "300px",
                                              height: "300px",
                                              zIndex: "100",
                                              opacity: "0.2",
                                              margin: "-150px 0 0 -150px",
                                            }}
                                          ></div>
                                        </td>
                                      </tr>
                                      <tr
                                        className="hd"
                                        style={{ width: "100%" }}
                                      >
                                        <td
                                          height="24"
                                          className="gray"
                                          colSpan="5"
                                          style={{
                                            fontWeight: "700",
                                            fontSmooth: "600",
                                            textAlign: "left",
                                            fontSize: "12px",
                                          }}
                                        >
                                          <span>
                                            {timeaccotolocation2(ele)}
                                            {/* {new Date(ele).toLocaleDateString(
                                              "en-us",
                                              {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                              }
                                            )} */}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{ padding: "0", width: "40%" }}
                                        >
                                          <table>
                                            <tr>
                                              <th
                                                style={{
                                                  fontWeight: "400",
                                                  textAlign: "center",
                                                  width: "60%",
                                                }}
                                              >
                                                Class
                                              </th>
                                              <th
                                                style={{
                                                  fontWeight: "400",
                                                  textAlign: "center",
                                                  width: "20%",
                                                }}
                                              >
                                                Heat
                                              </th>
                                              <th
                                                style={{
                                                  fontWeight: "400",
                                                  textAlign: "center",
                                                  width: "20%",
                                                }}
                                              >
                                                Score
                                              </th>
                                            </tr>
                                            {/* {classes
                                        .filter(
                                          (item) => item.rundate == day.run_date
                                        )
                                        .map((clas) => {
                                          return (
                                            <>
                                            <tr> <td>{clas.name}</td></tr>
                                            </> */}
                                            {data
                                              .filter(
                                                (item) =>
                                                  item.id == ep.user.id &&
                                                  item.round == 1 &&
                                                  item.rundate == ele
                                              )
                                              .map((cls, i) => {
                                                // if(cls.class==clas.name)
                                                return (
                                                  <>
                                                    <tr key={i}>
                                                      <td>{cls.class}</td>
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                          height: "40px",
                                                        }}
                                                      >
                                                        {cls.scratch == false
                                                          ? cls.heat
                                                          : "scratched"}
                                                      </td>

                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {cls.name ? "" : ""}
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              })}

                                            {/* ); */}
                                            {/* })} */}
                                          </table>
                                        </td>
                                        {round2Data ? (
                                          <td
                                            style={{
                                              padding: "0",
                                              width: "15%",
                                            }}
                                          >
                                            <table>
                                              <tr>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "50%",
                                                  }}
                                                >
                                                  Heat
                                                </th>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "50%",
                                                  }}
                                                >
                                                  Score
                                                </th>
                                              </tr>

                                              {data
                                                .filter(
                                                  (item) =>
                                                    item.id == ep.user.id &&
                                                    item.round == 2 &&
                                                    item.rundate == ele
                                                )
                                                .map((cls) => {
                                                  return (
                                                    <tr>
                                                      {/* <td>{cls.class}</td> */}
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                          height: "40px",
                                                        }}
                                                      >
                                                        {cls.scratch == false
                                                          ? cls.heat
                                                          : "scratched"}
                                                      </td>

                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {cls.name ? "" : ""}
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                            </table>
                                          </td>
                                        ) : (
                                          ""
                                        )}
                                        {round3Data ? (
                                          <td
                                            style={{
                                              padding: "0",
                                              width: "15%",
                                            }}
                                          >
                                            <table>
                                              <tr>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "50%",
                                                  }}
                                                >
                                                  Heat
                                                </th>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "50%",
                                                  }}
                                                >
                                                  Score
                                                </th>
                                              </tr>
                                              {data
                                                .filter(
                                                  (item) =>
                                                    item.id == ep.user.id &&
                                                    item.round == 3 &&
                                                    item.rundate == ele
                                                )
                                                .map((cls) => {
                                                  return cls.round == 3 ? (
                                                    <tr>
                                                      {/* <td>{cls.class}</td> */}
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                          height: "40px",
                                                        }}
                                                      >
                                                        {cls.scratch == false
                                                          ? cls.heat
                                                          : "scratched"}
                                                      </td>

                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {cls.name ? "" : ""}
                                                      </td>
                                                    </tr>
                                                  ) : (
                                                    <tr>
                                                      {/* <td>{cls.class}</td> */}
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        .
                                                      </td>

                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {cls.name ? "" : ""}
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                            </table>
                                          </td>
                                        ) : (
                                          ""
                                        )}
                                        {round4Data ? (
                                          <td
                                            style={{
                                              padding: "0",
                                              width: "15%",
                                            }}
                                          >
                                            <table>
                                              <tr>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "50%",
                                                  }}
                                                >
                                                  Heat
                                                </th>
                                                <th
                                                  style={{
                                                    fontWeight: "400",
                                                    textAlign: "center",
                                                    width: "50%",
                                                  }}
                                                >
                                                  Score
                                                </th>
                                              </tr>
                                              {data
                                                .filter(
                                                  (item) =>
                                                    item.id == ep.user.id &&
                                                    item.round == 4 &&
                                                    item.rundate == ele
                                                )
                                                .map((cls) => {
                                                  return cls.round == 4 ? (
                                                    <tr>
                                                      {/* <td>{cls.class}</td> */}
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                          height: "40px",
                                                        }}
                                                      >
                                                        {cls.scratch == false
                                                          ? cls.heat
                                                          : "scratched"}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {cls.name ? "" : ""}
                                                      </td>
                                                      {/* <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td> */}
                                                    </tr>
                                                  ) : (
                                                    <tr>
                                                      {/* <td>{cls.class}</td> */}
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                          height: "40px",
                                                        }}
                                                      ></td>
                                                      <td
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {cls.name ? "" : ""}
                                                      </td>
                                                      {/* <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      .
                                                    </td> */}
                                                    </tr>
                                                  );
                                                })}
                                            </table>
                                          </td>
                                        ) : (
                                          ""
                                        )}

                                        <td
                                          style={{ padding: "0", width: "15%" }}
                                        >
                                          <table>
                                            <tr>
                                              <th className="centerh">Total</th>
                                              <th className="centerh">Fees</th>
                                            </tr>
                                            {data
                                              .filter(
                                                (item) =>
                                                  item.id == ep.user.id &&
                                                  item.round == 1 &&
                                                  item.rundate == ele
                                              )

                                              .map((cls, index) => {
                                                // if(cls.class==clas.name)

                                                return (
                                                  <>
                                                    <tr key={i}>
                                                      <td
                                                        className="centerh"
                                                        style={{
                                                          height: "40px",
                                                        }}
                                                      ></td>

                                                      <td className="centerh">
                                                        
                                                        {index === 0 && i === 0
                                                          ? Number(
                                                              regischarge
                                                            ) + Number(fees)
                                                          : Number(fees)}
                                                        {/* { h=index} */}
                                                      </td>
                                                    </tr>
                                                  </>
                                                );
                                              })}
                                          </table>
                                        </td>
                                      </tr>
                                    
                                      <tr>
                                  
                                        <td
                                          colSpan="5"
                                          className="grayTotal"
                                          style={{
                                            textAlign: "right",
                                            padding: "6px 18px",
                                            fontSize: "10px",
                                          }}
                                        >
                                        
                                          {gjk === 0 ? (
                                            <>
                                              Total Due - $
                                              {
                                                (sumik =
                                                  Number(regischarge) +
                                                  Number(hjk + 1) *
                                                    Number(fees))
                                              }
                                            </>
                                          ) : null}
                                          {gjk > 0 ? (
                                            <>
                                              Total Due - $
                                              {
                                                (sumik =
                                                  // Number(regischarge) +
                                                  Number(hjk + 1) *
                                                  Number(fees))
                                              }
                                            </>
                                          ) : null}

                                          {/* Total Due - {sum=parseInt(regischarge)+((parseInt(h)) * parseInt(fees))} */}
                                          {/* {index} */}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </>
                              );
                            });
                          })}
                      </div>
                    </>
                  );
                })}
              </div>
              {/* </div> */}
            </div>
          </React.Fragment>
        </PDFExport>
        {/* </PdfContainer> */}
      </div>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import EventTab from "../../Components/EventTab/EventTab";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import userConstants from "../../shared/constants/user-constants";
import "./Club_result.scss";
const Club_upcoming = () => {
  const navigate = useNavigate();
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);

  const [eventDetails, setEventDetails] = useState([]);
  const [eventImg, setEventImg] = useState();
  const [eventDetails1, setEventDetails1] = useState([]);
  const [distric, setDistric] = useState([]);
  const [pageRecord, setPageRecord] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState("");
  const [currentDistric, setCurrentDistric] = useState("");
  const [hostClubIds, setHostClubIds] = useState("");
  const [isLoder, setIsLoder] = useState(true);
  const [fbicon, setFbicon] = useState("true");
  const [twiicon, setTwiicon] = useState("true");
  const [selectedClub, setSelectedClub] = useState(null);

  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      var userDistricId = getLocalData("user_info", "all").userData
        .login_user_detail.data.user.district.uzip_namba_district;
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      setCurrentDistric(userDistricId);

      getAllEventDetails(userDistricId);
    } else {
      getAllEventDetails(currentDistric);
    }

    let response = await combinedServices.getAllDistric();
    setDistric(response.data);
  }, []);

  const getAllEventDetails = async (dist_id) => {
    var eventArr = [];

    var data = {
      host_club_id: "",
      page: 1,
      records_per_page: pageRecord,
      event_status: "upcoming",
      sort_by: "event_start",
      order: "ASC",
    };
    if (dist_id) {
      data.district_id = dist_id;
    }

    let eventResponse = await combinedServices.getAllEventForHomePage(data);

    if (eventResponse.status == 200) {
      setTotalRecord(eventResponse.totalCount);

      eventResponse.data.map((events) => {
        if (events.event_is_published) {
          var data = {
            boatsCount: events.boatsCount,
            district_id: events.event_district_id,
            event_drivers_meeting_time: events.event_drivers_meeting_time,
            event_event_start: events.event_event_start,
            event_event_close: events.event_event_end,
            full_title: events.event_full_title,
            registration_close: events.event_registration_close,
            registration_open: events.event_registration_open,
            short_tile: events.event_short_tile,
            city: events.venue_clbsite_site_city,
            country: events.venue_clbsite_site_country,
            state: events.venue_clbsite_site_state,
            zipcode: events.venue_clbsite_site_zip,
            venue: events.venue_clbsite_site_name,
            hostClub_club_name: events.hostClub_club_name,
            racersCount: events.racersCount,
            id: events.event_id,
          };

          eventArr.push(data);
        }
      });
      var data1 = eventArr.sort(
        (a, b) =>
          new Date(a.event_event_start).getTime() -
          new Date(b.event_event_start).getTime()
      );
      data1.map((event,index)=>{
        if(index==0){
          handleEVent(event.id)
        }
      })
      setEventDetails(data1);

      setIsLoder(false);
    } else {
      setIsLoder(false);
    }
  };

  const handlePageChange = async (pageNumber) => {
    setPage(pageNumber);
    setIsLoder(true);
    var eventArr = [];

    var data = {
      host_club_id: "",
      district_id: currentDistric,
      page: pageNumber,
      records_per_page: pageRecord,
      event_status: "upcoming",
      sort_by: "event_start",
      order: "ASC",
    };

    let eventResponse = await combinedServices.getAllEventForHomePage(data);
    if (eventResponse.status == 200) {
      setTotalRecord(eventResponse.totalCount);

      eventResponse.data.map((events) => {
        if (events.event_is_published) {
          var data = {
            boatsCount: events.boatsCount,
            district_id: events.event_district_id,
            event_drivers_meeting_time: events.event_drivers_meeting_time,
            event_event_start: events.event_event_start,
            event_event_close: events.event_event_end,
            full_title: events.event_full_title,
            registration_close: events.event_registration_close,
            registration_open: events.event_registration_open,
            short_tile: events.event_short_tile,
            city: events.venue_clbsite_site_city,
            country: events.venue_clbsite_site_country,
            state: events.venue_clbsite_site_state,
            zipcode: events.venue_clbsite_site_zip,
            venue: events.venue_clbsite_site_name,
            hostClub_club_name: events.hostClub_club_name,
            racersCount: events.racersCount,
            id: events.event_id,
            event_is_published: events.event_is_published,
          };

          eventArr.push(data);
        }
      });
      var data1 = eventArr.sort(
        (a, b) =>
          new Date(a.event_event_start).getTime() -
          new Date(b.event_event_start).getTime()
      );
      setEventDetails(data1);

      setIsLoder(false);
    } else {
      setIsLoder(false);
    }
  };

  const handleDistricFilter = (e) => {
    getAllEventDetails(e.target.value);

    setCurrentDistric(e.target.value);
  };

  const HandleEventDetails = (id) => {
    navigate("/event-detail/" + id);
  };
  const calculateDaysBetweenTwoDate = (startDate, endDate) => {
    var future = moment(endDate);
    var start = moment(startDate);
    var d = future.diff(start, "days") + 1; // 9

    return d;
  };
  const checkEndRegistrationDate = (date) => {
    var str = "";

    if (
      moment(date).format("YYYY-MM-DD") >=
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      str = "Closes";
    } else if (
      moment(date).format("YYYY-MM-DD") <
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      str = "Closed";
    }

    return str;
  };
  const handleEVent=async(id)=>{
    setSelectedClub(id);
    let eventResponse = await combinedServices.getEventDetail(id);
    
    let eventDetail = eventResponse.data;
    if (eventDetail.event_image != "") {
      setEventImg(baseURL + "images/" + eventDetail.event_image);
    } else {
      setEventImg("https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/db_logo.png");
    }
    var event_contact = eventDetail.event_contacts.split(",");

    var data = {
      boatsCount: eventDetail.boatsCount,
      contacts: event_contact,
      status1: eventDetail.event_event_status,
      contact_user_areacodemobile: eventDetail.contact_user_areacodemobile,
      contact_user_areacodephone: eventDetail.contact_user_areacodephone,
      contact_user_email: eventDetail.contact_user_email,
      contact_user_firstname: eventDetail.contact_user_firstname,
      contact_user_lastname: eventDetail.contact_user_lastname,
      contact_user_mobile: eventDetail.contact_user_mobile,
      contact_user_phone: eventDetail.contact_user_phone,
      event_additional_boat_charge: eventDetail.event_additional_boat_charge,
      event_address: eventDetail.event_address,
      event_alternate_frequency_required:
        eventDetail.event_alternate_frequency_required,
      event_city: eventDetail.venue_clbsite_site_city,
      event_country: eventDetail.venue_clbsite_site_country,
      event_created_at: eventDetail.event_created_at,
      event_district_id: eventDetail.event_district_id,
      event_drivers_meeting_time: eventDetail.event_drivers_meeting_time,
      event_event_start: eventDetail.event_event_start,
      event_event_end: eventDetail.event_event_end,
      event_event_type: eventDetail.event_event_type,
      event_extra_rounds_per_class: eventDetail.event_extra_rounds_per_class,
      event_first_boat_charge: eventDetail.event_first_boat_charge,
      event_full_title: eventDetail.event_full_title,
      event_host_club_id: eventDetail.event_host_club_id,
      event_id: eventDetail.event_id,
      event_max_boat_limit: eventDetail.event_max_boat_limit,
      event_min_boats_per_class: eventDetail.event_min_boats_per_class,
      event_registration_close: eventDetail.event_registration_close,
      event_registration_open: eventDetail.event_registration_open,
      event_rounds_per_class: eventDetail.event_rounds_per_class,
      event_short_tile: eventDetail.event_short_tile,
      event_state: eventDetail.venue_clbsite_site_state,
      event_tent_camping: eventDetail.event_tent_camping,
      event_total_days: eventDetail.event_total_days,
      event_updated_at: eventDetail.event_updated_at,
      event_venue: eventDetail.venue_clbsite_site_name,
      event_zip: eventDetail.venue_clbsite_site_zip,
      hostClub_club_name: eventDetail.hostClub_club_name,
      racersCount: eventDetail.racersCount,
      event_image: baseURL + "images/" + eventDetail.event_image,
      event_detail: eventDetail.event_detail,
      hotels: eventDetail.event_hotels,
    };
    setEventDetails1(data);
  }
  return (
   
    <>
      <div className="mainCon">
        <Header />

        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              <ul className="eventTab flexBox">
                <li key="upcoming-event1">
                  <NavLink to="/club-upcoming">Upcoming Events</NavLink>
                </li>
                <li key="all-event1">
                  <NavLink to="/club-result">Completed Events</NavLink>
                </li>
                <li key="all-event1">
                  <NavLink to="/series-result">Race Series</NavLink>
                </li>
              </ul>
              <div>
                <div className="flexBox">
                  <div className="raceLft">
                    <div className="flexBox nowrap itemCenter districtSelectBox">
                      District
                      <select onChange={handleDistricFilter} defaultValue="">
                        <option value="">All District</option>
                        {distric.map((distVal) => {
                          return (
                            <option
                              key={distVal.district_id}
                              selected={
                                currentDistric == distVal.district_id
                                  ? "selected"
                                  : ""
                              }
                              value={distVal.district_id}
                            >
                              D{distVal.district_id}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {isLoder ? (
                      <div className="text-center mt-4 loadMore">
                        <Spinner color="primary" />
                      </div>
                    ) : (
                      <>
                        <ul className="clubList">
                          {eventDetails.length > 0 ? (
                            eventDetails.map((event, index) => {
                              return (
                                <li key={event.id} 
                                className={
                                  selectedClub === event.id
                                    ? "selected"
                                    : ""
                                }
                                onClick={() =>
                                  handleEVent(
                                    event.id
                                   
                                  )
                                }>
                                  <div
                                    className="Info"
                                    style={{
                                      width: "100%",
                                      fontSize: "15px",
                                      fontWeight: "600",
                                    }}
                                  >
                                      <a
                                        href="#"
                                        className={
                                          selectedClub === event.id
                                            ? "selected"
                                            : "baffff"
                                        }
                                      >
                                    {event.full_title ? event.full_title : ""}
                                    <br />
                                    {moment(event.event_event_start).format(
                                      "MMM D YYYY"
                                    )}{" "}
                                    -{" "}
                                    {moment(event.event_event_close).format(
                                      "MMM D YYYY"
                                    )}
                                    {(() => {
                                      if (
                                        moment(event.registration_open).format(
                                          "YYYY-MM-DD"
                                        ) <= moment().format("YYYY-MM-DD") &&
                                        moment().format("YYYY-MM-DD") <=
                                          moment(
                                            event.registration_close
                                          ).format("YYYY-MM-DD")
                                      )
                                        return null;
                                    })()}
                                     <span>D{event.district_id}</span>
                                    </a>
                                  </div>
                                  <div className="fbicon"></div>
                                </li>
                              );
                            })
                          ) : (
                            <div className="noEvent">
                              Not Found Any Upcoming Event.
                            </div>
                          )}
                        </ul>

                        {totalRecord > 10 && (
                          <Pagination
                            activePage={page}
                            totalItemsCount={totalRecord}
                            onChange={handlePageChange}
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="raceRgt">
                    <div className="raceRyt2">
                    <div className="inner evdetTop text-center">
                        <div className="img">
                        {eventDetails1.event_image==`${baseURL}` + "images/"?<img src={`/static/media/logo.d22ca8f16fd8e85ccbd9.png`} />:
                          <img src={`${eventDetails1.event_image}`} />}
                          {/* <img src={eventDetails1.event_image} alt="" /> */}
                        </div>
                        <div className="text">
                          <span>{eventDetails1.event_full_title}</span>
                          {moment(eventDetails1.event_event_start).format(
                            "ddd , MMM D , YYYY"
                          )}
                          <br />
                          Entries: {eventDetails1.racersCount} racers,{" "}
                          {eventDetails1.boatsCount} boats
                        </div>
                      </div>
                      <div className="inner location">
                        <span>Location</span>
                        {eventDetails1.event_venue}
                        <br />
                        {eventDetails1.event_city} , {eventDetails1.event_state} ,{" "}
                        {eventDetails1.event_zip}.<br />
                        <a href="#">Map</a> | <a href="#">Directions</a>
                      </div>
                      <div className="inner location">
                        <span>
                          Registration{" "}
                          {checkEndRegistrationDate(
                            moment(
                              eventDetails1.event_registration_close
                            ).format("YYYY-MM-DD")
                          )}{" "}
                          (
                          {moment(eventDetails1.event_registration_close).format(
                            "ddd , MMM D , YYYY"
                          )}
                          )
                        </span>
                      </div>

                      <div className="inner location">
                        {eventDetails1?.hotels && (
                          <>
                            <span>Hotel Reservations</span>
                            {eventDetails1?.hotels.map((value, index) => {
                              return (
                                <>
                                  <a href={`${value.url}`} target="_blank">
                                    {value.name}
                                  </a>{" "}
                                  {eventDetails1?.hotels.length == index + 1
                                    ? ""
                                    : "|"}
                                </>
                              );
                            })}
                          </>
                        )}

                        <br />
                        <br />
                        <span>Hosting Club</span>
                        <a href="#">{eventDetails1?.hostClub_club_name}</a>
                        <div className="contactInfo">
                        <p>{eventDetails1.contact_user_firstname} {eventDetails1.contact_user_lastname}-</p>
                        <p>{eventDetails1.contact_user_email}-
                          {eventDetails1.contact_user_mobile}</p>
                          </div>
                        {/* {eventDetails1?.contacts.map((cont_details) => {
                          return (
                            <>
                              <p className="contactInfo">{cont_details}</p>
                            </>
                          );
                        })} */}
                      </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Club_upcoming;

import React, { useEffect, useState } from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import { NavLink } from "react-router-dom";
import ResultTable from "../../Components/RaceSeries/ResultTable";
import combinedServices from "../../shared/services/user-service";

export default function RaceSeriesResult() {
  const [series, setSeries] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState(0);
  const [result, setResult] = useState([]);

  useEffect(() => {
    const fetchSeries = async () => {
      try {
        console.log("Fetching series...");
        const seriesResponse = await combinedServices.getSeries();
        console.log("seriesResponse ", seriesResponse);
        setSeries(seriesResponse.data);
      } catch (err) {
        console.error("Error fetching series data:", err);
      }
    };

    fetchSeries();
  }, []);

  useEffect(async () => {
    try {
      const id = series[selectedSeries].id;

      const getSeriesResult = await combinedServices.getResultForSeries({ id });
      setResult(getSeriesResult.data);
    } catch (err) {
      console.log("err ", err);
    }
  }, [selectedSeries,series]);

  const viewResult = (index) => {
    try {
      setSelectedSeries(index);
    } catch (err) {
      console.log("err ", err);
    }
  };

  return (
    <>
      <div className="mainCon">
        <Header />

        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Events</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Events</a>
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              {/* <EventTab /> */}
              <ul className="eventTab flexBox">
                <li key="upcoming-event1">
                  <NavLink to="/club-upcoming">Upcoming Events</NavLink>
                </li>
                <li key="all-event1">
                  <NavLink to="/club-result">Completed Events</NavLink>
                </li>
                <li key="all-event1">
                  <NavLink to="/series-result">Race Series</NavLink>
                </li>
              </ul>

              <div>
                <div className="flexBox">
                  <div className="raceLft">
                    {/* <div className="flexBox nowrap itemCenter districtSelectBox">
                      District
                      <select>
                        <option value="" selected="">
                          All District
                        </option>
                        <option value="1">D1</option>
                        <option value="2">D2</option>
                        <option value="3">D3</option>
                        <option value="4">D4</option>
                        <option value="5">D5</option>
                        <option value="6">D6</option>
                        <option value="7">D7</option>
                        <option value="8">D8</option>
                        <option value="9">D9</option>
                        <option value="10">D10</option>
                        <option value="11">D11</option>
                        <option value="12">D12</option>
                        <option value="13">D13</option>
                        <option value="16">D16</option>
                        <option value="17">D17</option>
                        <option value="19">D19</option>
                        <option value="20">D20</option>
                      </select>
                    </div> */}
                    <ul className="clubList">
                      {series.map((raceSeries, index) => {
                        return (
                          <li
                            className={
                              index == selectedSeries ? "selected" : ""
                            }
                            onClick={(e) => viewResult(index)}
                          >
                            <div className="Info">
                              <a
                                href="#"
                                className={
                                  index == selectedSeries ? "selected" : ""
                                }
                              >
                                {raceSeries.title}
                                <br />
                                {/* <span>D20</span> */}
                              </a>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="raceRgt">
                    <div>
                      
                      {result?.classes?.map((item) => {
                       return <ResultTable data={item} numberOfRaces={result.numberOfRaces}/>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

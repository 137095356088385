import React from "react";

const ResultTable = ({ data, numberOfRaces }) => {
  // Check if the participants array is empty
  if (!data.participants || data.participants.length === 0) {
    return null; // Do not render anything if there are no participants
  }

  return (
    <div className="rsultTable newTbl">
      <div className="d-flex justify-content-end p-2"></div>
      <div>
        <h5 style={{ marginLeft: "15px" }}>{data.className}</h5>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th
                className="tal"
                style={{
                  textAlign: "left",
                  paddingLeft: "15px",
                  width: "30%",
                }}
              >
                Drivers
              </th>
              {numberOfRaces.map((race, index) => (
                <th
                  key={index}
                  className="tar"
                  style={{
                    textAlign: "right",
                    width: "10%",
                    ...(index === numberOfRaces.length - 1 && {
                      paddingRight: "15px",
                    }),
                  }}
                >
                  R{index + 1}
                </th>
              ))}
              <th
                className="tar"
                style={{
                  textAlign: "right",
                  paddingRight: "15px",
                  width: "10%",
                }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {data.participants.map((participant, idx) => {
              // Calculate the total score for the participant
              const totalScore = numberOfRaces.reduce((sum, race) => {
                const raceScore = participant.score.find(
                  (score) => score.eventId === race
                );
                return sum + (raceScore ? raceScore.score : 0);
              }, 0);

              return (
                <tr key={idx}>
                  <td
                    className="tal"
                    style={{ textAlign: "left", paddingLeft: "15px" }}
                  >
                    {participant.participantName}
                  </td>
                  {numberOfRaces.map((race) => {
                    const raceScore = participant.score.find(
                      (score) => score.eventId === race
                    );
                    return (
                      <td
                        key={race}
                        className="tar"
                        style={{ textAlign: "right" }}
                      >
                        {raceScore ? raceScore.score : 0}
                      </td>
                    );
                  })}
                  <td
                    className="tar"
                    style={{ textAlign: "right", paddingRight: "15px" }}
                  >
                    <b>{totalScore}</b>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResultTable;

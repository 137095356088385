import { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Typography,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Button,
  TextField,
} from "@mui/material";
import swal from "sweetalert";
import getLocalData from "../../shared/localStorage/getData";
import combinedServices from "../../shared/services/user-service";
import getRemoveData from "../../shared/localStorage/removeData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius:3
};

export default function RaceSeriesModal({ open, handleClose }) {
  const [raceSeriesTitle, setRaceSeriesTitle] = useState("");
  const [raceSeriesDescription,setRaceSeriesDescription] = useState("")
  const [selectedRaces, setSelectedRaces] = useState([]);
  const [userId,setUserId] = useState("")

  useEffect(async() => {
    

    async function getProfile(){
      if (getLocalData("user_info", "isLogin")) {
        var loginUserData = getLocalData("user_info", "all").userData
          .login_user_detail.data.user;
        var userDistricId = getLocalData("user_info", "all").userData
          .login_user_detail.data.user.district.uzip_namba_district;
        var userId = getLocalData("user_info", "all").userData.login_user_detail
          .data.user.id;
        setUserId(userId);
        var userClubDetails = getLocalData("user_info", "all").userData
          .login_user_detail.data.user.clubOfficerIn;
        var data = {
          user_id: loginUserData.id,
          clubs: true,
        };
        let profileResponse = await combinedServices.getProfileDetails(data);
        const clubNamesArray = profileResponse?.data?.club_members?.map(
          (item) => {
            if (item.club) {
              return item.club.club_name;
            } else {
              return item.club_name;
            }
          }
        );
  
        let clubByDistric = await combinedServices.getAllClubForDistric(
          userDistricId
        );
        if (userClubDetails.length != 0) {
          let dropdwnOption = await combinedServices.getAllEventTemplateDetails(
            Number(userClubDetails[0]?.id)
          );
        }
        if (clubByDistric.status == 200) {
          if (loginUserData.districtDirector != null) {
          } else {
            const filteredArray1 = clubByDistric?.data?.filter((item) =>
              clubNamesArray.includes(item.club_name)
            );

          }

        }
  
        let classResponse = await combinedServices.getAllClasses(userDistricId);
        if (classResponse.status == 401) {
          swal(classResponse.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        if (classResponse.status == 200) {
          const clsDropdown = [];
          classResponse.data
            .filter((x) => x.status === "active")
            .map((cls) => {
              var categoryName =
                cls.class_category_id == "6" ? "EX-" + cls.name : cls.name;
              var options = {
                name: categoryName,
                id: cls.id,
              };
              clsDropdown.push(options);
            });
        } else {
          swal("Opps", classResponse.msg, "error");
        }
  
      }
  
    }

 
    async function fetchData() {
      const res = await combinedServices.getRacesForSeries()
      if (res.data) {
        const raceData = res.data.map((item) => ({
          ...item,
          selected: false,
        }));
        setSelectedRaces(raceData);
      }
    }
    await fetchData();
    await getProfile();
  }, []);



  const handleCheckboxChange = (event, index) => {
    const updatedRaces = [...selectedRaces];
    updatedRaces[index].selected = event.target.checked;
    setSelectedRaces(updatedRaces);
  };

  const handleSubmit =async (event) => {
    event.preventDefault();
    const selectedRaceIds = selectedRaces
      .filter((race) => race.selected)
      .map((race) => race.id);
    let data = {
      title:raceSeriesTitle,
      description:raceSeriesDescription,
      eventIds:selectedRaceIds,
      created_by:userId
    }
    try{
      const createdResponse = await combinedServices.createSeries(data)
      handleClose();
      console.log('createdResponse',createdResponse)
      swal("Series Created").then(()=>{
      })
    }catch(err){
      handleClose();
      swal("Series Not Created").then(()=>{
      })
      console.log('err',err)
    }
    
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h5" component="h5" gutterBottom>
          Create Race Series
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Race Series Name"
            value={raceSeriesTitle}
            onChange={(e)=>setRaceSeriesTitle(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Race Series Description"
            value={raceSeriesDescription}
            onChange={(e)=>setRaceSeriesDescription(e.target.value)}
            fullWidth
            margin="normal"
          />
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {selectedRaces.map((item, index) => (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={item.selected}
                      onChange={(e) => handleCheckboxChange(e, index)}
                      name={item.full_title}
                    />
                  }
                  label={item.full_title}
                />
              ))}
              {/* </Box> */}
            </FormGroup>
            <button type="submit" className="swal-button">
              Submit
            </button>
          </FormControl>
        </form>
      </Box>
    </Modal>
  );
}

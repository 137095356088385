import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import React, { useState, useEffect, useReducer } from "react";
import moment from "moment";
import EventTab from "../../Components/EventTab/EventTab";
import "./AddEvent.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import combinedServices from "../../shared/services/user-service";
import { useLocation, useParams } from "react-router-dom";
import { NavLink, Link, useNavigate } from "react-router-dom";
import userConstants from "../../shared/constants/user-constants";
import Login from "../../Pages/Login/Login";
import getLocalData from "../../shared/localStorage/getData";
import EditJoinRace from "./UpdateEventStep1";
import swal from "sweetalert";
import getRemoveData from "../../shared/localStorage/getData";

const EventDetail = () => {
  const params = useParams();
  let navigate = useNavigate();

  const [eventDetails, setEventDetails] = useState();
  const [eventId, setEventId] = useState("");
  const [driverData, setDriverData] = useState([]);
  const [classData, setClassData] = useState([]);
  const [driverModal, setDriverModal] = useState(false);
  const [classModal, setClassModal] = useState(false);
  const [userID, setUserId] = useState("");
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [isLogin, setIsLogin] = useState(false);
  const [isJointEvent, setIsJointEvent] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [participantIds, setParticipantIds] = useState("");
  const [editEntryRaceButton, setEditEntryRaceButton] = useState(false);
  const [authoriseduser, setAuthorisedUser] = useState([]);
  const [contestdir, setContestDir] = useState("");
  const [addentr, setAddEnter] = useState(false);

  const location = useLocation().pathname.split("/")[2];

  // const[loginUser,setLoginUser]=useState("");
  useEffect(async () => {
    if (getLocalData("user_info", "isLogin")) {
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;

      setUserId(userId);
      setUserStatus(
        getLocalData("user_info", "all").userData.login_user_detail.data.user
          .user_status
      );
    }
    //setUserStatus("inactive")
    setEventId(params.id);
    let eventResponse = await combinedServices.getEventDetail(params.id);
    if (eventResponse.status == 401) {
      swal(eventResponse.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }

    let eventDetail = eventResponse.data;
    var event_contact = eventDetail.event_contacts.split(",");

    var data = {
      boatsCount: eventDetail.boatsCount,
      contacts: event_contact,
      contact_user_areacodemobile: eventDetail.contact_user_areacodemobile,
      contact_user_areacodephone: eventDetail.contact_user_areacodephone,
      contact_user_email: eventDetail.contact_user_email,
      contact_user_firstname: eventDetail.contact_user_firstname,
      contact_user_lastname: eventDetail.contact_user_lastname,
      contact_user_mobile: eventDetail.contact_user_mobile,
      contact_user_phone: eventDetail.contact_user_phone,
      event_additional_boat_charge: eventDetail.event_additional_boat_charge,
      event_address: eventDetail.event_address,
      event_alternate_frequency_required:
        eventDetail.event_alternate_frequency_required,
      event_city: eventDetail.venue_clbsite_site_city,
      event_country: eventDetail.venue_clbsite_site_country,
      event_created_at: eventDetail.event_created_at,
      event_district_id: eventDetail.event_district_id,
      event_drivers_meeting_time: eventDetail.event_drivers_meeting_time,
      event_event_start: eventDetail.event_event_start,
      event_event_type: eventDetail.event_event_type,
      event_extra_rounds_per_class: eventDetail.event_extra_rounds_per_class,
      event_first_boat_charge: eventDetail.event_first_boat_charge,
      event_full_title: eventDetail.event_full_title,
      event_host_club_id: eventDetail.event_host_club_id,
      event_id: eventDetail.event_id,
      event_max_boat_limit: eventDetail.event_max_boat_limit,
      event_min_boats_per_class: eventDetail.event_min_boats_per_class,
      event_registration_close: eventDetail.event_registration_close,
      event_registration_open: eventDetail.event_registration_open,
      event_rounds_per_class: eventDetail.event_rounds_per_class,
      event_short_tile: eventDetail.event_short_tile,
      event_state: eventDetail.venue_clbsite_site_state,
      event_tent_camping: eventDetail.event_tent_camping,
      event_total_days: eventDetail.event_total_days,
      event_updated_at: eventDetail.event_updated_at,
      event_venue: eventDetail.venue_clbsite_site_name,
      event_zip: eventDetail.venue_clbsite_site_zip,
      hostClub_club_name: eventDetail.hostClub_club_name,
      racersCount: eventDetail.racersCount,
      event_image: baseURL + "images/" + eventDetail.event_image,
      event_detail: eventDetail.event_detail,
      hotels: eventDetail.event_hotels,
      authorizatized: eventDetail.event_authorized_user_ids,
      event_updatedBy: eventDetail.event_updated_by,
    };
    setAuthorisedUser(data.authorizatized);
    setContestDir(data.event_updatedBy);
    setEventDetails(data);
    if (
      userId === data.event_updatedBy ||
      data.authorizatized.includes(userId)
    ) {
      setAddEnter(true);
    }
    handleAttendees(params.id);
  }, []);

  const handleAttendeesOnChange = async (e) => {
    var data = {
      event_id: eventId,
    };
    if (e.target.value == 1) {
      data.classes = true;

      let eventResponse = await combinedServices.getEventParticipate(data);
      setDriverData(eventResponse.data);
      setDriverModal(true);
      setClassModal(false);
    } else if (e.target.value == 2) {
      let eventResponse = await combinedServices.getEventParticipateByClasses(
        data
      );
      setClassData(eventResponse.data);
      setClassModal(true);
      setDriverModal(false);
    }
  };
  const handleAttendees = async (id) => {
    setDriverModal(true);
    var data = {
      event_id: id,
      classes: true,
    };

    let eventResponse = await combinedServices.getEventParticipate(data);
    setDriverData(eventResponse.data);
  };

  const checkLoginForJoinEvent = async () => {
    if (userID) {
      if (userStatus == "active") {
        let registeredUser = await combinedServices.checkIfUserJoined(eventId);

        if (registeredUser.status == 200) {
          if (!registeredUser.data.payment) {
            navigate("../event/add-event-step3/" + registeredUser.data.id, {
              replace: true,
            });
          }
        } else {
          window.location.href = "/event/add-event/" + eventId;
        }
      } else {
        swal("Opps", "User status is inactive");
      }
    } else {
      setIsLogin(true);
    }
  };
  const handleEnterEvent = () => {
    if (userID > 0) {
      navigate("../manage-entries-step1/" + eventId, { replace: true });
    } else {
      setIsLogin(true);
    }
  };
  const handleHotelRedirection = (url) => {
    window.location.href = "/event/add-event/" + eventId;
  };

  const checkEndRegistrationDate = (date) => {
    var str = "";

    if (
      moment(date).format("YYYY-MM-DD") >=
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      str = "Closes";
    } else if (
      moment(date).format("YYYY-MM-DD") <
      moment(new Date()).format("YYYY-MM-DD")
    ) {
      str = "Closed";
    }

    return str;
  };

  const handleEditEntries = (id) => {
    ////////
    setParticipantIds(id);

    if (userID === contestdir || authoriseduser.includes(userID)) {
      setEditEntryRaceButton(true);
    } else {
      setEditEntryRaceButton(false);
    }
  };

  const handleDeleteParticipant = async (id, user_id) => {
    if (userID === contestdir || authoriseduser.includes(userID)) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this participant!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          var data = {
            id: id,
            user_id: user_id,
          };
          let participantResponse = await combinedServices.deleteParticipant(
            data
          );
          if (participantResponse.status == 200) {
            swal("Participant delete successfully").then(() => {
              handleAttendees(eventId);

              //navigate("../manage-entries/"+id, { replace: true });
            });
          } else {
            swal("!Opps", participantResponse.msg, "error");
          }
        } else {
          swal("Your participant  is safe!");
        }
      });
    }
  };

  var currentURL = window.location.href;

  return (
    <>
      <div className="mainCon">
        <Header />

        {isLogin ? (
          <Login isLogin={isLogin} path={"/event/add-event/" + eventId} />
        ) : (
          ""
        )}

        <div className="innerBanner event">
          <div className="container flexBox">
            <div>
              <div className="innerHd">Manage Entries</div>
              <div className="bradcrumb">
                <a href="/">Home</a>
                <a href="/event-management">Event Management</a>
                {editEntryRaceButton ? (
                  <a href={`/manage-entries/${location}`}>Manage Entries</a>
                ) : (
                  ""
                )}

                {/* <a href={`/manage-entries/${eventId}`}>Manage Enteries</a> */}

                {/* <a href={`/race-interface/${eventId}`}>Run Interface</a> */}
                {/* <a href={`/run-race/${eventId}`}>Score</a> */}
              </div>
            </div>
          </div>
        </div>

        <div className="pb50 grayBg">
          <div className="container">
            <div className="whtBox">
              {editEntryRaceButton ? (
                <EditJoinRace participateId={participantIds} />
              ) : (
                <div className="eventDetBot">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="hd">Manage Entries</div>
                    <div className="flexBox nowrap itemCenter districtSelectBox">
                      {addentr && (
                        <a
                          href="#"
                          onClick={handleEnterEvent}
                          className="blueBtn"
                        >
                          Add Entry
                        </a>
                      )}
                    </div>
                  </div>

                  {driverModal && (
                    <Accordion allowZeroExpanded="true" preExpanded={[0]}>
                      {driverData?.length > 0
                        ? driverData
                            ?.sort((a, b) =>
                              a.user.user_firstname.localeCompare(
                                b.user.user_firstname
                              )
                            )
                            .map((value, index) => {
                              const allClassesCancelled = value.classes?.every(
                                (cls) =>
                                  cls.participation_status === "CANCELLED"
                              );

                              return (
                                <>
                                  {allClassesCancelled == false ? (
                                    <>
                                      <AccordionItem uuid={index}>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            {value.user && (
                                              <div className="accQ d-flex justify-content-between">
                                                <div className="d-flex col-12 justify-content-between align-items-center">
                                                  <NavLink
                                                    to={
                                                      "/race-result-profile/" +
                                                      value.user.id
                                                    }
                                                  >
                                                    {value.user.user_firstname +
                                                      " " +
                                                      value.user.user_lastname}
                                                  </NavLink>
                                                  <div className="d-flex align-items-center">
                                                    <button
                                                      className="entriesButtonEdit me-2"
                                                      onClick={() =>
                                                        handleEditEntries(
                                                          value.id
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      <span className="material-icons-outlined">
                                                        edit
                                                      </span>
                                                    </button>
                                                    <button
                                                      className="entriesButton"
                                                      onClick={() =>
                                                        handleDeleteParticipant(
                                                          value.id,
                                                          value.user.id
                                                        )
                                                      }
                                                    >
                                                      {" "}
                                                      <span className="material-icons-outlined">
                                                        delete
                                                      </span>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <div className="acctable">
                                            <table>
                                              {value.classes?.map(
                                                (cls, index) =>
                                                  cls.participation_status !==
                                                    "CANCELLED" && (
                                                    <tr
                                                      key={cls.transponder_id}
                                                    >
                                                      {cls.transponder_id !==
                                                      0 ? (
                                                        <table className="userinfo">
                                                          {index === 0 && (
                                                            <thead>
                                                              <tr>
                                                                <th>Class</th>
                                                                <th>
                                                                  Transponder Id
                                                                </th>
                                                                <th>
                                                                  Transponder
                                                                  Name
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                          )}
                                                          <tbody>
                                                            <tr>
                                                              <td>
                                                                {
                                                                  cls.class
                                                                    ?.name
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  cls.transponder_id
                                                                }
                                                              </td>
                                                              <td>
                                                                {
                                                                  cls.transponder_name
                                                                }
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      ) : (
                                                        <td>
                                                          {cls.class?.name}
                                                        </td>
                                                      )}
                                                    </tr>
                                                  )
                                              )}
                                            </table>
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                        : ""}
                    </Accordion>
                  )}
                  {classModal && (
                    <Accordion allowZeroExpanded="true" preExpanded={[0]}>
                      {classData.length > 0
                        ? classData.map((value, classIndex) => {
                            return (
                              <>
                                <AccordionItem uuid={classIndex}>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <div className="accQ d-flex justify-content-between">
                                        <div>{value.class_name}</div>
                                        <div></div>
                                      </div>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <div className="acctable">
                                      <table>
                                        {value.participants.map(
                                          (participate) =>
                                            participate.eventParticipantClass_participation_status !=
                                              "CANCELLED" && (
                                              <tr>
                                                <td>
                                                  <NavLink
                                                    to={
                                                      "/race-result-profile/" +
                                                      participate.user_user_id
                                                    }
                                                  >
                                                    {participate.user_user_firstname +
                                                      " " +
                                                      participate.user_user_lastname}
                                                  </NavLink>
                                                </td>
                                              </tr>
                                            )
                                        )}
                                      </table>
                                    </div>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </>
                            );
                          })
                        : ""}
                    </Accordion>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default EventDetail;

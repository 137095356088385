import React, { useState,useEffect } from 'react';
import getLocalData from "../../../shared/localStorage/getData";
import getRemoveData from "../../../shared/localStorage/removeData";
import { NavLink } from "react-router-dom";
import Login from "../../../Pages/Login/new_login";
const Header = () => {

  const [isLogin, setIsLogin] = useState(false) ;
  const [isClubDashboard,setIsClubDashboard] = useState(false) ;
  const [adminIsLogin,setAdminIsLogin] = useState(false) ;
  const [isLoggedIn, setIsLoggedIn] = useState(true);
 
const logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png"

  useEffect(() => {
    if(getLocalData("user_info", "isLogin")){
    var loginData =  getLocalData("user_info", "all").userData.login_user_detail.data.user.clubOfficerIn ;
    setIsLogin(getLocalData("user_info", "isLogin"));
    setIsClubDashboard(loginData.length > 0 ? true :false)
    }
    if(getLocalData("admin_info", "isLogin")){
      setAdminIsLogin(true)
    }
   
  }, [])

    const handleLogout = () => {
       
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/";
    }
    const handleRegister = () => {
       
      
      window.location.href = "https://member.namba.com/index.php?page=getstarted";
  }
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

  return (


    <header className="header" id="header">
      <div className="container">
        <div className="logo"><a href="/"><img src={logo} alt="NAMBA" /></a></div>
        <a className={isActive ? 'showMobMenu active' : 'showMobMenu'} 
      onClick={toggleClass} ><span><i></i><i></i><i></i></span></a>

        <div className={isActive ? 'headerInner flexBox itemCenter spacebetween active' : 'headerInner flexBox itemCenter spacebetween'}>
          <nav className="menu">
            <ul className="flexBox">
              <li key="home" className="active"><NavLink to="/">Home</NavLink></li>
              {/* <li key="about-us"><NavLink to="/about-us">About</NavLink></li> */}
              {/*<li><NavLink to="/latest-news">Latest News</NavLink></li>*/}
              <li key="event"><NavLink to="/upcoming-event">Events</NavLink></li>
              <li key="club"><NavLink to="/club">Club</NavLink></li>
              <li key="club-result"><NavLink to="/club-result">Results</NavLink></li>
              {isLogin && <li key="my-races"><NavLink to="/my-race">My Races</NavLink></li>}
              <li key="store"><NavLink to="/store">Store</NavLink></li>

            </ul>
          </nav>

          <div className="headerRgt flexBox itemCenter">

            {/* <ul className="socialLinks flexBox">
              <li key="linkein"><a href="#"><img src={linkein} alt="Linkedin" /></a></li>
              <li key="twitter"><a href="#"><img src={twitter} alt="Twitter" /></a></li>
              <li key="facebook"><a href="https://www.facebook.com/NAMBAInt"><img src={facebook} alt="Facebook" /></a></li>
              <li key="instagram"><a href="#"><img src={instagram} alt="Instagram" /></a></li>
  </ul> */}
            {isLogin ?
            <div className="topButtons flexBox">  
             <div className='linkInner'><NavLink to="/club-dashboard">Dashboard </NavLink></div>
                       
             <div className='linkInner'><a href="#" onClick={handleLogout}>Logout</a></div>
              
            </div>
            : <>
             <div className="topButtons flexBox"> 
            {adminIsLogin ? <div className='linkInner'><NavLink to="/admin-dashboard">Dashboard</NavLink></div> :<div className='linkInner'><Login /></div>}
          <div className='linkInner'><NavLink to="" onClick={handleRegister}>Register</NavLink></div>
            </div>
            </>}
            </div>
        </div>
      </div>
      
    </header>
    


  )


}
export default Header;
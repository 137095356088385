import React from 'react'
import iconSvg from '../../assets/images/social_icon_image.svg'
import Header from '../../Components/Layout/Header/Header'

function Store() {
  return (
    <div>
        <Header/>
        <div class="nam_bann">
        <div class="nam_bann_inner">
            <h2>COMING SOON</h2>
            <p>ARE YOU READY</p>
            <div class="socialIcWrp">
                {/* <!-- <a href="#"><img src="images/linkedIn" alt="social icon"></a>
                <a href="#"><img src="images/linkedIn" alt="social icon"></a>
                <a href="#"><img src="images/linkedIn" alt="social icon"></a>
                <a href="#"><img src="images/linkedIn" alt="social icon"></a> --> */}
                <img src={iconSvg} alt="social icon"/>
            </div>
        </div>

    </div>
    
    </div>
  )
}

export default Store